<template>
  <div class="simple-multi-example">
    <vue-meeting-selector
        ref="meetingSelector"
        class="simple-multi-example__meeting-selector"
        v-model="meeting"
        :date="date"
        :loading="loading"
        :class-names="classNames"
        :calendarOptions="calendarOptions"
        :meetings-days="meetingsDays"
        :multi="true"
        :nb-days-to-display="nbDaysToDisplay"
        @change="sendMeetings"
        @next-date="nextDate"
        @previous-date="previousDate">
      <template
          #header="{ meetings }">
        <div class="title">{{ formattingDateTitle(meetings.date) }}</div>
      </template>
      <template #button-up><span></span></template>
      <template #button-down><span></span></template>
      <template #button-next><span></span></template>
      <template #button-previous><span></span></template>

    </vue-meeting-selector>
    <!--<p>{{ meeting.length ? '' : 'No Meeting selected' }}</p>-->
    <!--
    <div>
      <ul class="list-unstyled">
        <li v-for="item in meeting" :key="item.id">
          {{ formattingMeeting(item) }}
        </li>
      </ul>
    </div>-->
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, computed} from 'vue';
import VueMeetingSelector from 'vue-meeting-selector';
import slotsGenerator from '@/components/meetingselector/slotsGenerator';

export default defineComponent({
  name: 'SimpleMultiExample',
  components: {
    VueMeetingSelector,
  },
  setup() {
    const date = ref(new Date('2023-11-12T06:00:00'));
    const meetingsDays = ref([]);
    const meeting = ref([]);
    const loading = ref(true);
    const meetingSelector = ref(null);
    const nbDaysToDisplay = computed(() => 7);
    const calendarOptions = computed(() => ({
      limit: 8,
    }));
    const classNames = computed(() => ({
      tabClass: 'loading-div',
    }));

    const slotsGeneratorAsync = (d, n, start, end, timesBetween) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(slotsGenerator(d, n, start, end, timesBetween));
          }, 1000);
        });

    const nextDate = async () => {
      loading.value = true;
      const start = {
        hours: 14,
        minutes: 0,
      };
      const end = {
        hours: 21,
        minutes: 0,
      };
      const dateCopy = new Date(date.value);
      const newDate = new Date(dateCopy.setDate(dateCopy.getDate() + 7));
      date.value = newDate;
      meetingsDays.value = await slotsGeneratorAsync(
          newDate,
          nbDaysToDisplay.value,
          start,
          end,
          60
      );
      loading.value = false;
    };

    const previousDate = async () => {
      loading.value = true;
      const start = {
        hours: 8,
        minutes: 0,
      };
      const end = {
        hours: 16,
        minutes: 0,
      };
      const dateCopy = new Date(date.value);
      dateCopy.setDate(dateCopy.getDate() - 7);
      const formattingDate = (dateToFormat) => {
        const d = new Date(dateToFormat);
        const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
        const month =
            d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
      };
      const newDate =
          formattingDate(new Date()) >= formattingDate(dateCopy)
              ? new Date()
              : new Date(dateCopy);
      date.value = newDate;
      meetingsDays.value = await slotsGeneratorAsync(
          newDate,
          nbDaysToDisplay.value,
          start,
          end,
          60
      );
      loading.value = false;
    };

    onMounted(async () => {
      const start = {
        hours: 14,
        minutes: 0,
      };
      const end = {
        hours: 21,
        minutes: 0,
      };
      meetingsDays.value = await slotsGeneratorAsync(
          date.value,
          nbDaysToDisplay.value,
          start,
          end,
          60
      );
      //console.log('...meeting', this.meeting)
      //console.log('...myavail', this.myAvailableMeetings)
      //this.meeting = this.myAvailableMeetings;
      loading.value = false;
    });
    const formattingDate = (dateToFormat) => {
      const d = new Date(dateToFormat);
      const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
      const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    };
    const getDayAcronym = (date) => {
      const daysOfWeekAcronyms = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, and so on
      return daysOfWeekAcronyms[dayOfWeek];
    }

    const formattingDateTitle = (dateToFormat) => {
      const d = new Date(dateToFormat);
      return getDayAcronym(d);
      //const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
      //const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      //return `${month}-${day}`;
    };

    const formattingTime = (dateToFormat) => {
      const d = new Date(dateToFormat);
      const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
      const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
      return `${hours}:${minutes}`;
    };
    const formattingMeetings = (meetings) => {
      return meetings.map(m => formattingMeeting(m));

    };

    const formattingMeeting = (m) => {
      return `${getDayAcronym(m.date)} ${formattingTime(m.date)}`;
    };
    const isPreviousDisabled = computed(() => {
      const d = new Date(date.value);
      d.setDate(d.getDate() - 1);
      return formattingDate(d) < formattingDate(new Date());
    });

    const meetingSelectedClass = (m) => {
      if (!meeting.value) {
        return '';
      }
      const selectedDate = new Date(m.date);
      const d = new Date(meeting.value.date);

      if (selectedDate.getTime() === d.getTime()) {
        return 'meeting--selected';
      }
      return '';
    };

    const selectMeeting = (m) => {
      if (meeting.value) {
        const selectedDate = new Date(m.date);
        const d = new Date(meeting.value.date);
        if (selectedDate.getTime() !== d.getTime()) {
          meeting.value = m;
        } else {
          meeting.value = null;
        }
      } else {
        meeting.value = m;
      }
    };

    const nextMeetings = () => {
      meetingSelector.value?.nextMeetings();
    };

    const previousMeetings = () => {
      meetingSelector.value?.previousMeetings();
    };
    return {
      date,
      meetingsDays,
      meeting,
      loading,
      nbDaysToDisplay,
      classNames,
      nextDate,
      previousDate,
      formattingDateTitle,
      formattingTime,
      isPreviousDisabled,
      meetingSelectedClass,
      selectMeeting,
      nextMeetings,
      previousMeetings,
      meetingSelector,
      formattingMeetings,
      calendarOptions,
      formattingMeeting,
    };
  },
  methods: {
    sendMeetings(value) {
      this.$emit('getMeetings', this.formattingMeetings(value));
    },
  }
});
</script>

<style scoped lang="scss">
.simple-multi-example {
  &__meeting-selector {
    max-width: 542px;
  }
}
:deep(.loading-div) {
  top: 58px!important;
}

:deep(.tab__loading__text) {
  position: relative;
  bottom: 30px;
}
</style>

