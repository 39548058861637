import axios from 'axios';

import {config} from '../config';
import {auth} from "@/firebase.config";

import {getStorage, ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";

export async function updateProfileImageLink(data) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.post(
        `${host}/user/profile_image/` + auth.currentUser.uid,
        {
            data
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}

export async function getProfileImageLink() {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/user/profile_image/` + auth.currentUser.uid,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}


export async function updateProfileImage(file) {
    const storage = getStorage();

// Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, 'profile/' + auth.currentUser.uid);
    const uploadTask = uploadBytesResumable(storageRef, file);

// Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        },
        (error) => {
            console.log(error);
        },
        () => {
            console.log("upload is done")
        }
    );
}

export async function getProfileImage(uid) {
    const storage = getStorage();
    const starsRef = ref(storage, 'profile/' + uid + '_224x224');
    let url = '';
    try {
         url = await getDownloadURL(starsRef);
    } catch (e) {
        console.log(e);
    }
    return url;
}