import {
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification
//    onAuthStateChanged,
//    getIdToken,
} from 'firebase/auth'
import {auth} from '@/firebase.config'
import {createUser} from "@/api";

export const authStore = {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        user(state) {
            return state.user
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('authenticated', true);
            console.log('...user', user)
            localStorage.setItem('authUser', JSON.stringify(user));

        },
        clearUser(state) {
            state.user = null
            localStorage.removeItem('authenticated');
            localStorage.removeItem('authUser');
            localStorage.removeItem('code');
            localStorage.removeItem('role');
        },
    },
    actions: {
        async register(context, data) {
            const response = await createUser(data)
            if (response.success) {
                // TODO email verification
                signInWithEmailAndPassword(auth, data.email, data.password).then(() => {
                    context.commit('setUser', auth.currentUser)
                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            console.log('Email verification sent!');
                        });
                });
            } else {
               throw new Error('Error: Please create your account again.')
            }
        },

        async logIn(context, submission) {
            const response = await signInWithEmailAndPassword(auth, submission.email, submission.password)
            if (response) {
                context.commit('setUser', response.user)
            } else {
                throw new Error('login failed')
            }
        },

        async logOut(context) {
            await signOut(auth)
            context.commit('clearUser')
        },

        async fetchUser(context, user) {
            if (user) {
                context.commit("setUser", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                context.commit("clearUser");
            }
        },
        async authSignIn(context, user) {
            context.commit('setUser', user);
        }

    }
}
