<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="onr-banner">
    <div class="onr-banner__item">
      <router-link class="onr-banner__item--link questions-link" to="/contact">Have any questions?
        <!-- <span class="banner__email">
          <svg fill="none" height="24px" width="24px" viewBox="0 0 24 24">
            <path
                d="M444.556,85.218H48.942C21.954,85.218,0,107.171,0,134.16v225.177c0,26.988,21.954,48.942,48.942,48.942h395.613  c26.988,0,48.941-21.954,48.941-48.942V134.16C493.497,107.171,471.544,85.218,444.556,85.218z M460.87,134.16v225.177  c0,2.574-0.725,4.924-1.793,7.09L343.74,251.081l117.097-117.097C460.837,134.049,460.87,134.096,460.87,134.16z M32.628,359.336  V134.16c0-0.064,0.033-0.11,0.033-0.175l117.097,117.097L34.413,366.426C33.353,364.26,32.628,361.911,32.628,359.336z   M251.784,296.902c-2.692,2.691-7.378,2.691-10.07,0L62.667,117.846h368.172L251.784,296.902z M172.827,274.152l45.818,45.819  c7.512,7.511,17.493,11.645,28.104,11.645c10.61,0,20.592-4.134,28.104-11.645l45.82-45.819l101.49,101.499H71.327L172.827,274.152z  "/>
          </svg>
          admin@thedreaminc.org
        </span> -->
      </router-link>
    </div>
    <div class="onr-banner__login">
      <div class="onr-banner__item login__item">
        <div v-if="!loggedIn">
          <a class="onr-banner__item--link login-link" @click="openLogin()">Sign Up / Login </a>
        </div>
        <template v-else>
          <a class="onr-banner__item--link" @click="navigateToPage(`StudentPage`)">My Page</a>
          <span class="space"></span>
          <a class="onr-banner__item--link" @click="logOut()">LogOut</a>
        </template>
      </div>
    </div>
  </div>
  <LoginOverlay :showModal="showLoginModal" @close="close"></LoginOverlay>
  <!--<PopupOverlay v-if="showPopupModal && !loggedIn" :showModal="showPopupModal" @close="closePopup"></PopupOverlay>-->

</template>

<script>
import LoginOverlay from '../components/overlays/loginOverlay.vue';
//import PopupOverlay from "@/components/overlays/popupOverlay.vue";
import {auth} from '@/firebase.config'

export default {
  name: 'banner-block',
  props: {
    msg: String
  },
  data() {
    return {
      showLoginModal: false,
      showPopupModal: true,
    };
  },
  components: {
    //PopupOverlay,
    LoginOverlay,
  },
  computed: {
    loggedIn() {
      return localStorage.getItem('authenticated') && auth.currentUser;
    },
  },
  mounted() {

  },
  created() {
    const isFirstVisit = localStorage.getItem('isFirstVisit');

    if (!isFirstVisit) {
      this.showPopupModal = true;
      localStorage.setItem('isFirstVisit', 'true');
    }
  },
  methods: {
    openLogin() {
      this.showLoginModal = true;
    },
    close() {
      this.showLoginModal = false;
    },
    closePopup() {
      this.showPopupModal = false;
    },
    logOut() {
      this.$store.dispatch('authStore/logOut').then(() => {
        this.$router.go({
          path: '/',
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    navigateToPage(name) {
      this.$router.push({
        name,
      });
    }
  }
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.space {
  margin-right: 20px;
}

.onr-banner {
  background-color: $blue-1;
  display: flex;
  list-style-type: none;
  margin: 0;
  overflow: hidden;

  &__divider {
    border-right: 2px solid white;
    margin: 8px;
  }

  &-home.onr-banner__item {
    font-size: 15px;
    font-weight: 800;

    &:hover {
      border-bottom: unset;
      color: unset;
    }

    &.active {
      background-color: unset;
      color: unset;
    }
  }

  &__login {
    margin-left: auto;
    margin-right: 10px;
    order: 2;

    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  &__item {
    text-align: left;
    float: left;
    color: white;
    cursor: pointer;
    height: 100%;
    text-decoration: none;
    font-size: 15px;
    margin: 0.5rem;

    &.login__item {
      // padding: 6px 0;

      &.login__item__login {
        margin-left: 5px;
      }
    }

    .onr-banner__item--link {
      vertical-align: sub;

      &.questions-link {
        margin-left: 10px;
      }

      &.login-link {
        margin-right: .7rem;
      }

      .banner__email {
        fill: white;
      }

      &:link {
        color: white;
        text-decoration: none;
      }

      &:visited {
        color: white;
        text-decoration: none;
      }

      &:hover {
        color: white;
        text-decoration: none;
      }

      &:active {
        color: white;
        text-decoration: none;
      }

    }

    &__menu {
      display: none;
      list-style-type: none;
      margin: 5px 0;
      overflow: hidden;
      padding: 0 14px;
    }

    &.active {
      background-color: #04AA6D;
      color: white;
    }
  }

  & .onr-image-container {
    padding: 14px 16px;

    &__profile {
      margin-left: auto;

      .onr-banner__item--link {
        display: flex;
      }

      &-text {
        font-size: 14px;
        padding-left: 9px;
        margin-right: 10px;
      }
    }

    &:hover {
      background-color: unset;
      border-bottom: unset;
      color: unset;
    }
  }
}

</style>