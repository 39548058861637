<template>
  <div v-if="authenticated" class="onr-body__create-account">
    <div class="onr-body__create-account--heading">
      <h1>Create Account</h1>
      <div class="onr-body__create-account-already">
        <p class="onr-body__create-account-already--text">Already have an account? 
          <router-link class="onr-body__create-account-already--link" to="/LogIn">Login.</router-link>
        </p>
      </div>
    </div>
    <div class="onr-body__create-account-form">
			<CreateAccountForm></CreateAccountForm>
    </div>
  </div>
</template>

<script>
import CreateAccountForm from './CreateAccountForm.vue'

export default {
  name: 'CreateAccount',
  components: {
		CreateAccountForm,
  },
	data() {
		return {
		};
	},
	mounted() {

	},
	computed: {
    authenticated() {
      return localStorage.getItem('authenticated');
    },
	},
	methods: {
	}
}
</script>

<style lang="scss">
@import '../../utils/_colors.scss';

.onr-body {
  &__create-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;

    &--heading {
      color: $blue-1;
      margin-bottom: 1rem;

      h1 {
        margin-bottom: 0.5rem;
      }
    }

    &-form {
      display: inline-grid;

			&--roles {
				display: flex;
				margin: auto;
			}

			&--role {
				border: 1px solid $neutral-2;
				border-radius: 6px;
				padding: 10px 80px 10px 10px;
				margin-bottom: 20px;

				&:first-child {
					margin-right: 5px;
				}
			}

      &--email, &--password {
        border: 1px solid $neutral-2;
        border-radius: 20px;
        font-size: 14px;
        height: 40px;
        padding-left: 18px;
        margin-bottom: 10px;
        width: 400px;
      }

      &--submit {
        background-color: $green-1;
        border: none;
        color: white;
        cursor: pointer;
        font-size: 16px;
        height: 40px;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 200px;
      }

    }

    &-heading {
      font-size: 35px;
    }

    &-already {
      &--text {
        font-size: 14px;
        font-weight: 400;
        margin: 3px 0 30px;
      }

      &--link {
        color: $green-1;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
</style>