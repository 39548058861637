<template>
  <div class="row">
    <div class="col-12 text-center">
      <h3 class="heading">Profile Image</h3>
    </div>
    <div class="col-md-5 offset-md-1">
      <form>
        <div class="form-group">
          <input type="file" accept="image/*" @change="previewImage" class="image-input" id="my-file">
					<!-- <button @change="previewImage"></button> -->
          <template v-if="preview">
						<img :src="preview" class="preview-image" />
					</template>
        </div>
      </form>
    </div>
	</div>
</template>
  
<script>

import {getProfileImage, updateProfileImage} from "@/api/image";
import {auth} from "@/firebase.config";
export default {
	name: 'ImageUploader',
	data: () => {
		return {
			preview: null,
			image: null,
			preview_list: [],
			image_list: []
		};
	},
  mounted() {
    getProfileImage(auth.currentUser.uid).then((response) => {
      this.preview = response;
    }).catch((error) => {
      console.log(error);
    });
  },
  methods: {
		previewImage: function(event) {
			var input = event.target;
			if (input.files) {
				var reader = new FileReader();
				reader.onload = (e) => {
					this.preview = e.target.result;
				}
				this.image=input.files[0];
				reader.readAsDataURL(input.files[0]);
        updateProfileImage(this.image).then((response) => {
          console.log(response);
          //alert("Image Uploaded");
        }).catch((error) => {
          console.log(error);
        });

			}
		},
		previewMultiImage: function(event) {
			var input = event.target;
			var count = input.files.length;
			var index = 0;
			if (input.files) {
				while(count --) {
					var reader = new FileReader();
					reader.onload = (e) => {
						this.preview_list.push(e.target.result);
					}
					this.image_list.push(input.files[index]);
					reader.readAsDataURL(input.files[index]);
					index ++;
				}
			}
		},
		reset: function() {
			this.image = null;
			this.preview = null;
			this.image_list = [];
			this.preview_list = [];
		}
	},
};
</script>

<style lang="scss" scoped>
@import '../utils/_colors.scss';

.form-group {
	display: flex;
  flex-direction: column;
}

.preview-image {
	width: 120px;
	height: 120px;
	object-fit: cover;
	position: absolute;
}

.heading {
	margin-bottom: 7px;
}

.image-input::file-selector-button {
	margin-bottom: 1rem;
	border: none;
	padding: 3rem 1.5rem;
	width: 120px;
	height: 120px;
}

</style>
