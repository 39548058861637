<template>
  <h1 class="onr__about-us-title" id="about-us">About Us</h1>

  <div class="onr__about-us">

    <div class="onr__about-us-container">
      <div class="about-us__container--image">
        <img class="about-us__image" src="../assets/About-us_04.png"/>
      </div>
      <div class="about-us__container--text">
        <h1 class="onr__about-us-heading">Our Story</h1>
        <p class="onr__about-us-subheading"></p>
        <span class="about-us__divider"></span>
        <p class="onr__about-us-description--sub">Founded in 2020 during a period of global transformation, The Dream International Inc emerged with a distinct vision. In response to the challenges presented by COVID-19, we sought to channel our energy into a meaningful initiative. Our primary objective was clear: to empower individuals in North Africa and the Middle East by equipping them with English language skills, thus enhancing their prospects for better employment. What began with just 15 students has evolved into a multifaceted endeavor, impacting the lives of over 500 students across six nations. Our initial inspiration - the idea that continuous learning can illuminate the path forward - has grown into The Dream's commitment to democratizing education.</p>
      </div>
    </div>
    <div class="onr__about-us-container">
      <div class="about-us__container--image">
        <img class="about-us__image" src="../assets/About-us_07.png"/>
      </div>
      <div class="about-us__container--text">
        <h1 class="onr__about-us-heading">Our Values</h1>
        <p class="onr__about-us-subheading"></p>
        <span class="about-us__divider"></span>
        <p class="onr__about-us-description--sub">At the heart of The Dream International Inc beats a commitment to democratizing dreams. We believe that irrespective of circumstance, dreaming should not be a luxury. Life's challenges may be formidable, but they should not stifle aspirations. Our core value lies in the conviction that education is the cornerstone of change. It is a potent force that has the capacity to transform not just individual lives, but entire communities. In an ever-evolving world, we embrace the values of resilience, determination, and a relentless pursuit of dreams, nurturing a belief that education is the catalyst for sustainable change.
        </p>
      </div>
    </div>
    <div class="onr__about-us-container">
      <div class="about-us__container--image">
        <img class="about-us__image" src="../assets/About-us_09.png"/>
      </div>
      <div class="about-us__container--text">
        <h1 class="onr__about-us-heading">Our Education</h1>
        <p class="onr__about-us-subheading"></p>
        <span class="about-us__divider"></span>
        <p class="onr__about-us-description--sub">The Dream's educational journey is more than a curriculum; it's a passport to dreams. Understanding that language proficiency and technological literacy are the bedrock of empowerment, we offer a dynamic educational experience. Focused on English and computer coding, our programs extend beyond conventional methodologies. Our interactive learning platforms redefine language acquisition, making it dynamic and natural. The goal is clear: to equip our students with the tools they need to dream ambitiously. In a world where dreaming is often perceived as a luxury, we are laying the foundation for dreams to be realized through accessible, innovative, and transformative education.</p>
      </div>
    </div>
  </div>
  <div class="onr__flexible-learning">
    <h1 class="flexible-learning__title">Flexible Learning</h1>
    <img class="flexible-learning__image" src="../assets/map.png"/>
  </div>
  <p class="onr__about-us-text"> We are exempt from federal income tax under Internal Revenue Code Section 501(c)(3) as a non-profit organization. As such, your contribution is tax-deductible to the extent allowed by law, and in accordance with applicable regulations. No goods or services were provided in exchange for your generous donation. Thank you for your contribution to The Dream Inc.</p>
</template>



<script>

export default {
  name: 'MeetTheTeam',
  components: {
  },
  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr__flexible-learning {
  .flexible-learning {
    &__image {
      object-fit: cover;
      max-width: 100%;
      height: auto;
    }

    &__title {
      margin: 2rem 1rem 0.5rem;
      text-align: left;
    }
  }
}

.onr__about-us {
  &-title {
    margin: 7rem 0 10px 20px;
    text-align: left;
    //margin-top: 105.76px;
    margin-top: 125.76px;
  }
  &-text {
    margin: 0 0 10px 20px;
    text-align: left;
    //margin-top: 105.76px;
    //margin-top: 125.76px;
  }

  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    
    &:nth-child(2) {
      flex-direction: row-reverse;

      .about-us__container--text {
        text-align: right;

        .about-us__divider {
          margin-left: auto;
          margin-right: 30px;
        }
      }

    }

    .about-us__container--image {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      flex-wrap: wrap;
      justify-content: center;

      .about-us__image {
        object-fit: cover;
        max-width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }

    .about-us__container--text {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      background-color: $beige-1;
      color: $grey-1;
      text-align: left;

      .onr__about-us {
        &-heading {
          color: black;
          margin: 30px 30px 0 30px;
          font-weight: 500;
        }

        &-subheading {
          margin: 10px 30px 8px 30px;
        }

        &-description--sub {
          margin: 35px 30px 30px;
        }
        &-description--sub2 {
          margin: 35px 30px 30px;
        }
      }

      .about-us__divider {
        display: block;
        border-bottom: 2px solid black;
        margin-left: 30px;
        width: 40px;
      }
    }

    @media only screen and (max-width: 959px) {
      .about-us__container--image {
        flex: 1 0 50%;
      }
    }

    @media only screen and (max-width: 959px) {
      .about-us__container--text {
        flex: 0 1 100%;
      }
    }
  }
}

</style>
