import axios from 'axios';

import { config } from '../config';

export async function referral(code) {
  const host = `${config.service.host}/api`;
  const response = await axios.get(
    `${host}/referral/` + code,
    {
      headers: {

      },
    }
  );
  return response.data;
}
