<template>
  <label class="onr-checkbox__container" :for="checkboxId">{{name}}
    <input
			:id="checkboxId"
			type="checkbox"
      :checked="checked"
			v-model="checked"
      @change="onChange()"
		>
    <span class="checkbox-checkmark"></span>
	</label>
</template>
<script>
export default {
  name: 'Checkbox-block',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: ['modelValue', 'name', 'value'],
  data: () => ({
    checked: false,
  }),
  computed: {
    hasMultipleCheckboxes() {
      return this.modelValue instanceof Array;
    },
    checkboxId() {
      return `${this.name}-checkbox-option`;
    },
  },
  watch: {
    value: function updateValue() {
      this.setCheckedProperty();
    },
    modelValue: function updateModel() {
      this.setCheckedProperty();
    },
  },
  created() {
    this.setCheckedProperty();
  },
  methods: {
    onChange() {
      let valueToEmit = this.checked;
      if (this.hasMultipleCheckboxes) {
        const updatedModelValue = [...this.modelValue];
        if (this.checked) {
          updatedModelValue.push(this.value);
        } else {
          updatedModelValue.splice(updatedModelValue.indexOf(this.value), 1);
        }
        valueToEmit = updatedModelValue;
      }
      // TODO - cleanup
      if (valueToEmit) {
        this.$emit('checked');
      }
      if (!valueToEmit) {
        this.$emit('unchecked');
      }
    },
    setCheckedProperty() {
      this.checked = this.hasMultipleCheckboxes ? this.modelValue.includes(this.value) : this.modelValue === true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../utils/_colors.scss';

.onr-checkbox {
  &__container {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 4px;

    &:hover input ~ .checkbox-checkmark {
      background-color: $white-1;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkbox-checkmark {
        background-color: $blue-1;
      }

      &:checked ~ .checkbox-checkmark:after {
        display: block;
      }
    }

    .checkbox-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: $white-1;
      margin-top: 5px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 3px;
        height: 8px;
        border: solid $white-1;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

  }
}

</style>