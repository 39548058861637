<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="onr-program onr-program__hero">
    <img class="onr-program onr-program__hero__image" src="../assets/Program-top.png"/>
  </div>
  <div class="onr-program onr-program__panels" id="program">
    <div class="onr-program onr-program__block onr-program__panel--main">
      <h1 class="onr-program onr-program__heading">Our program</h1>
      <h3 class="onr-program onr-program__subheading">Interactive Virtual One-to-One Learning</h3>
      <p class="onr-program onr-program__description">Welcome to The Dream, where education knows no bounds! At the heart of our mission lies a commitment to education equity, transcending barriers of location, gender, and religion. Introducing our Interactive Virtual One-to-One Learning platform, designed to revolutionize the way we learn. Say goodbye to one-size-fits-all education and embrace a personalized journey tailored to your unique needs. With live, one-on-one teaching sessions, we're not just shaping minds; we're cultivating a future where every learner can thrive. Join The Dream, where education meets individuality, and let's unlock boundless possibilities together.</p>
    </div>
    <template
      v-for="(program, index) in programs"
      :key="index"
    >
      <div class="onr-program onr-program__block onr-program__panel">
        <div class="onr-program onr-program__container__image">
          <!-- <div class="onr-program onr__outline"></div> -->
          <img class="onr-program onr__stock-photo" :src="program.image"/>
        </div>
        <div class="onr-program onr-program__container__text">
          <h1 class="onr-program onr-program__heading">{{program.title}}</h1>
          <h3 class="onr-program onr-program__subheading">{{program.subtitle}}</h3>
          <p class="onr-program onr-program__description--sub">{{program.description}}</p>
        </div>
      </div>
    </template>
  </div>
  <!-- <QuestionBlock></QuestionBlock> -->
</template>

<script>
// import QuestionBlock from '../blocks/questionBlock.vue';
import programs from '../data/programs.json';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Program',
  components: {
    // QuestionBlock,
  },
  data() {
		return {
    };
  },
  mounted() {
    this.programs;
    window.scrollTo({top: 0, behavior: 'smooth'});
  },
  computed: {
    programs() {
			return programs;
		},
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../utils/_colors.scss';

.onr {
  &-program {
    &__block {
      display: flex;
      margin: 5rem;
    }

    &.onr-program__panel {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media only screen and (max-width: 959px) {
        .onr-program__container__image {
          flex: 1 0 50%;
        }
      }

      @media only screen and (max-width: 959px) {
        .onr-program__container__text {
          flex: 0 1 100%;
        }
      }

      .onr-program__container__text {
        margin-left: 7rem;
      }

      &:nth-child(3) {
        flex-direction: row-reverse;

        .onr-program__container__text {
          margin-left: unset;
          margin-right: 7rem;
        }
      }
    }

    &__hero {
      margin: 0;
      margin-top: 105.76px;

      &__image {
        margin: 0;
        width: 100%;

        @media only screen and (max-width: 770px) {
          object-fit: cover;
          height: 14rem;
          vertical-align: middle;
        }
      }
    }

    &__panels {
      background-color: $neutral-1;
      padding-bottom: 1px;
      padding-top: 7rem;

    }

    &__panel {
      &--main {
        display: block;
        text-align: left;
        margin: 0 5rem 10rem 5rem;
      }

      &--first {
        margin-top: 200px;
      }
    }

    &__heading {
      font-size: 3em;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 1.2;
      text-transform: uppercase;

      @media only screen and (max-width: 770px) {
        font-size: 2em;
      }
    }

    &__subheading {
      color: $blue-2;
      margin: 0;
      font-size: 2em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1.2em;
      }
    }

    &__description {
      font-size: 1.3em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1em;
      }

      &--sub {
        font-size: 1.2em;
        font-weight: 500;

        @media only screen and (max-width: 770px) {
        font-size: 1em;
      }
      }
    }

    &__container {
      &__text {
        text-align: left;
        width: 100%;
      }

      &__image {
        // position: relative;

        .onr__outline {
          border: 3px solid $blue-1;
          border-radius: 50%;
          height: 422px;
          -o-object-fit: cover;
          object-fit: cover;
          width: 422px;
          position: absolute;
          left: -14px;
          top: -14px;  
        }

        .onr__stock-photo {
          border-radius: 50%;
          height: 25em;
          object-fit: cover;
          width: 25em;
        }
      }
    }
  }

  &__testimony {
    &-title {
      color: black;
      font-size: 40px;
      margin: 0;
      padding-top: 40px;
      text-transform: uppercase;
    }

    &-subheading {
      margin-top: 5px;
    }
  }

  &__quote {
    background-color: #848484;
    height: 300px;
    margin: 200px 0 100px;

    &__text {
      color: white;
      font-size: 30px;
      font-family: Optima, sans-serif;
      padding: 100px 230px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 770px) {
  .onr-program.onr-program__panels {
    padding-top: 2rem;

    .onr-program.onr-program__block {
      margin: 0 2rem 5rem 2rem;

      &.onr-program__panel {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        width: 100%;

        .onr-program.onr-program__container__text {
          margin: 2rem;
        }

        .onr-program.onr-program__container__image {
          .onr__outline {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .onr-program.onr-program__panels {
    .onr__stock-photo {
      width: 14em;
      height: 14em;
    }
  }
}
</style>