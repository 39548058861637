<template>
  <div v-if="showModal" class="onr-alert">
    <div class="onr-alert__modal" role="dialog">
      <div class="onr-alert__modal-container">
        <div class="onr-alert__modal-container--left"></div>
        <div class="onr-alert__modal-container--right">
          <h2 class="overlay__heading">Are you sure you want to deactivate?</h2>
          <p>If you no longer wish to continue in this program, click confirm to deactivate</p>
          <button class="onr-alert__button" type="submit" @click="confirm()">Confirm</button>
          <a class="create-account-close" @click="closeModal()">
            <svg height="15px" id="Layer_1" viewBox="0 0 512 512" width="15px">
              <path
                  d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/>
            </svg>
          </a>
				</div>
			</div>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'alert-overlay',
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      showEmailError: false,
      showPasswordError: false,
      verified: false,
      login: 0,
      page: 0,
      showCreateAccount: false,
      rememberMe: false,
      submission: {
        email: '',
        password: '',
      }
    };
  },
  mounted() {
  },
  components: {
  },
  computed: {
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    closeModal() {
      localStorage.removeItem('code');
      localStorage.removeItem('role');
      this.login = 0;
      this.$emit('close');
    },
  },
  watch: {},
}
</script>

<style lang="scss">
@import '../../utils/_colors.scss';

.onr-alert {
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  @media only screen and (max-width: 650px) {
    .onr-alert__modal {
      width: 20em;
    }
  }

  &__button {
    background-color: $blue-1;
    border-radius: 25px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    margin-right: 0.5rem;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 200px;
    text-transform: uppercase;

    &.button--disabled {
      background-color: $grey-2;
      cursor: none;
      pointer-events: none;

      &:hover {
        background-color: $grey-2;
        border: none;
        cursor: unset;
      }
    }

    &:hover {
      background-color: $blue-1;
      border: solid 3px $blue-1;
      cursor: pointer;
    }
  }

  &__modal {
    display: flex;
    position: absolute;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 400px;
    height: 280px;
    background: #FFF;
    z-index: 999;
    transform: none;

    &.full-overlay {
      justify-content: center;
      width: 90%;
      height: 40em;
      overflow: scroll;
    }

    &-container {
      padding: 2rem;
      display: flex;
      position: absolute;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      text-align: center;
      width: 400px;
      height: 280px;
      background: #FFF;
      z-index: 999;
      transform: none; //TODO: clean this up

      @media only screen and (max-width: 430px) {
        width: 14em;
      }

      @media only screen and (max-width: 650px) {
        .onr-alert__modal-container--left {
          display: none;
        }
        width: 20em;
      }

      &--left {
        height: inherit;

        .login-img {
          max-width: 20em;
          height: inherit;
        }
      }

      &--left, &--right {
        flex-direction: column;
      }

      &--right {

        .overlay__heading {
          flex-grow: 1;
        }

        &.referral-container {
          display: flex;
          align-items: center;
        }

        .password-error, .email-error {
          color: red;
          font-size: 10px;
          margin-bottom: 10px;
          text-align: left;
          margin-left: 1rem;
        }

        display: flex;
        flex-direction: column;
        width: inherit;

        .no-account-text {
          font-size: 15px;
          text-align: left;
          margin: 40px 0 0 25px;
        }

        .button {
          &-login, &-create {
            background-color: $blue-1;
            border-radius: 25px;
            border: unset;
            height: 40px;
            margin: 5px 20px;
            font-size: 15px;
            text-transform: uppercase;
            color: white;
            cursor: pointer;
          }
        }

        .text-input__wrapper {
          margin: 10px 20px;
          width: auto;

          .text-input {
            width: 90%;
          }
        }
      }
    }
  }
}

</style>