<template>
	<!-- <div class="onr__contact-us-container">
		<img class="onr__contact-us-photo" src="../assets/About-us-top.png"/>
	</div> -->
	<div class="onr__contact-us">
		<div class="onr__contact-us-container--info">
			<h1 class="onr__contact-us-title" id="contact-us">Contact Us</h1>
			<p class="onr__contact-us-subtitle">We would love to hear from you! Please fill out this form and we will get in touch with you shortly.</p>
			<table class="onr__contact-us-extra">
        <tr>
          <td class="content-label">
						<img class="info-icon" src="../assets/email.svg"/>
					</td>
          <td>admin@thedreaminc.org</td>
        </tr>
      </table>
		</div>
		<div class="onr__contact-us-container--form">
			<div class="input__container contact-us">
        <span class="input__label contact-us">Name</span>
        <input v-model="form.name" class="onr-body__create-form--full contact-us" type="text" name="name" required>
      </div>
      <div class="input__container contact-us">
        <span class="input__label contact-us">Email</span>
        <input v-model="form.email" class="onr-body__create-form--full contact-us" type="text" name="email" required>
      </div>
			<div class="input__container contact-us">
        <span class="input__label contact-us">Message</span>
        <textarea v-model="form.message" class="onr-body__create-form--full textarea" rows="8" cols="50" type="textarea" name="message" required></textarea>
      </div>
			<button class="send-message-button" @click="sendMessage()">Send Message</button>
		</div>
	</div>
</template>


<script>

import {sendAdminEmail} from "@/api/email";

export default {
  name: 'MeetTheTeam',
	data() {
    return {
      form: {
				name: '',
				email: '',
				message: '',
			},
		}
	},
  components: {
  },
  mounted() {
	window.scrollTo({top: 0, behavior: 'smooth'});
  },
  methods: {
		sendMessage() {
			//send message here
      sendAdminEmail(this.form).then((response) => {
        console.log(response);
        alert("Message Sent");
        this.form.name = '';
        this.form.email = '';
        this.form.message = '';
      }).catch((error) => {
        console.log(error);
      });
		}
  }
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr__contact-us {
	display: flex;
	align-items: center;
	flex-direction: row;
	margin: 10rem 1rem;

	&-photo {
		margin-top: 105.76px;
		width: 100%;

		@media only screen and (max-width: 770px) {
			object-fit: cover;
			height: 14rem;
			vertical-align: middle;
		}
	}

	@media only screen and (max-width: 950px) {
		flex-direction: column;
	}

	&-extra {
		width: 200px;
    text-align: left;
		margin: 3rem;
		font-size: 1.1em;

		@media only screen and (max-width: 550px) {
			font-size: .8em;
		}

		@media only screen and (max-width: 950px) {
			margin: 2rem 0;
		}

		tr {
			.content-label {
				.info-icon {
					width: 20px;
					margin-top: 4px;
					margin: 4px 5px 3px 5px;

					@media only screen and (max-width: 550px) {
						margin-top: 0;
					}
				}
			}
		}
	}

	&-title {
		margin: 0;
		font-size: 3em;
		text-align: left;

		@media only screen and (max-width: 550px) {
			font-size: 2em;
		}
	}

	&-subtitle {
		color: $grey-1;
		font-size: 1.2em;
		text-align: left;
    width: 80%;

		@media only screen and (max-width: 550px) {
			width: unset;
			font-size: 1em;
		}
	}

	&-container {
		&--info {
			width: 40%;
			margin: 4rem 6rem 6rem 10rem;

			@media only screen and (max-width: 1200px) {
				margin: 2rem;
				width: 56%;
			}

			@media only screen and (max-width: 550px) {
				margin: 0;
				width: 87%;
			}
		}
	}
}

.input__container {
	&.contact-us {
		flex-direction: column;
    align-items: baseline;
		margin-bottom: 1rem;

		.input__label {
			&.contact-us {
				margin-bottom: .5rem;
			}
		}
	}
}

.onr-body__create-form--full {
	&.contact-us {
		height: 2.5rem;
		width: 28rem;

		@media only screen and (max-width: 550px) {
			width: 18rem;
		}
	}

	&.textarea {
		width: 28rem;
		font-size: 1.5em;

		@media only screen and (max-width: 550px) {
			width: 18rem;
		}
	}
}

.send-message-button {
	background-color: $blue-1;
	color: white;
	font-size: 16px;
	border: solid 3px $blue-1;
	cursor: pointer;
	font-weight: 400;
	margin-top: 20px;
	padding: 13px 15px;
  text-transform: uppercase;
	float: left;
}

</style>
