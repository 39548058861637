<template>
  <div class="onr-faq" id="faq">
    <h1 class="onr-faq__title">Frequently Asked Questions</h1>
    <p class="onr-faq__subtitle">Here are answers to most common questions.</p>
    <div class="onr-faq__questions">
      <Accordion
        v-for="(item, index) in questions"
        :key="index"
        :question="item.question"
        :content="item.answer"
      ></Accordion>
    </div>
    <div class="onr-faq__more">
      <span class="onr-faq__more-email">
        <svg id="Layer_1" style="enable-background:new 0 0 64 64;" version="1.1" viewBox="0 0 64 64" xml:space="preserve">
          <g>
            <g id="Icon-Envelope" transform="translate(78.000000, 232.000000)">
              <path class="st0" d="M-22.5-213.2l-1.9-1.9l-17.6,17.6c-2.2,2.2-5.9,2.2-8.1,0L-67.7-215l-1.9,1.9l13.1,13.1     l-13.1,13.1l1.9,1.9l13.1-13.1l2.6,2.6c1.6,1.6,3.7,2.5,5.9,2.5s4.3-0.9,5.9-2.5l2.6-2.6l13.1,13.1l1.9-1.9l-13.1-13.1     L-22.5-213.2" id="Fill-3"/><path class="st0" d="M-26.2-181.6h-39.5c-2.3,0-4.2-1.9-4.2-4.2v-28.2c0-2.3,1.9-4.2,4.2-4.2h39.5     c2.3,0,4.2,1.9,4.2,4.2v28.2C-22-183.5-23.9-181.6-26.2-181.6L-26.2-181.6z M-65.8-215.5c-0.8,0-1.4,0.6-1.4,1.4v28.2     c0,0.8,0.6,1.4,1.4,1.4h39.5c0.8,0,1.4-0.6,1.4-1.4v-28.2c0-0.8-0.6-1.4-1.4-1.4H-65.8L-65.8-215.5z" id="Fill-4"/>
            </g>
          </g>
        </svg>
      </span>
      <p class="onr-faq__more-text">
        Need more help?<br>
        Get in touch with us, support is provided daily <router-link class="contact-us--link" to="/contact">Email: admin@thedreaminc.org</router-link>
      </p>
    </div>
  </div>
</template>


<script>
import Accordion from '../components/accordion.vue';
import faq from '../data/faq.json';

export default {
  name: 'FAQ',
  components: {
    Accordion,
  },
  mounted() {
		this.questions;
    window.scrollTo({top: 0, behavior: 'smooth'});
	},
	computed: {
		questions() {
			return faq;
		},
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr {
  &-faq {
    margin-top: 105.76px;

    &__hero {
      margin: 0;

      &__image {
        margin: 0;
        width: 100%;
      }
    }

    &__title {
      text-align: center;
      margin: 9rem 3rem 0;
    }

    &__subtitle {
      text-align: center;
      margin: 0 3rem 3rem 3rem;
    }

    &__more {
      display: flex;
      margin: 4rem 2rem;
      align-items: center;
      justify-content: center;

      &-text {
        margin: 15px;
        text-align: left;

        .contact-us--link {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &-email {
        border: 2px solid black;
        border-radius: 50%;
        height: 40px;
        object-fit: cover;
        width: 40px;
        
        svg {
          margin-top: 2px;
          width: 35px;
        }
      }

    }
  }
}

</style>
