<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="onr-footer">
		<div class="onr-footer__column">
			<img class="onr-footer__logo" src="../assets/thedream_logo_white.png"/>
		</div>
		<div class="onr-footer__column">
			<h3 class="onr-footer__title">Site Map</h3>
			<li class="onr-footer__item">
				<router-link class="onr-footer__item--link" to="/about-us">About Us</router-link>
			</li>
			<li class="onr-footer__item">
				<router-link class="onr-footer__item--link" to="/program">Program</router-link>
			</li>
			<li class="onr-footer__item">
				<router-link class="onr-footer__item--link" to="/find-students">Find a Student</router-link>
			</li>
      <li class="onr-footer__item">
        <router-link class="onr-footer__item--link" to="/supportus">Support Us</router-link>
      </li>
			<li class="onr-footer__item">
				<router-link class="onr-footer__item--link" to="/faq">FAQ</router-link>
			</li>

      <li class="onr-footer__item">
        <router-link class="onr-footer__item--link" to="/nondiscriminatory-policy">Nondiscriminatory Policy</router-link>
      </li>
		</div>
		<div class="onr-footer__column">
			<h3 class="onr-footer__title">Get In Touch</h3>
			<li class="onr-footer__item">
				<router-link class="onr-footer__item--link" to="/contact">Email: admin@thedreaminc.org</router-link>
			</li>
		</div>
  </div>
</template>

<script>
export default {
  name: 'footer-block',
  props: {
    msg: String
  },
  methods: {
    navigateToPage(name) {
      this.$router.push({
        name,
      });
    },
  }
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr-footer {
	flex-wrap: wrap;
	width: 100%;
	align-items: flex-start;
	background-color: $blue-1;
	display: flex;
	flex-direction: row;
  list-style-type: none;
  margin: 0;
	padding: 50px 0;
  overflow: hidden;

	@media only screen and (max-width: 960px) {
		justify-content: center;
	}

	@media only screen and (max-width: 960px) {
		.onr-footer__column {
			flex: 1 0 50%;

			&:nth-child(1) {
				margin: 0 0 2rem 4rem;
			}

			&:nth-child(2),
			&:nth-child(3) {
				margin: 0 2rem 2rem 2rem;
			}
		}
	}



	&__logo {
		width: 200px;
	}

	&__title {
		color: white;
		margin: 0 0 5px 25px;
	}

	&__column {
		display: flex;
		flex-direction: column;
		margin: 0 50px;
		text-align: left;
	}

  &__item {
    float: left;
    color: white;
    cursor: pointer;
    height: 100%;
    padding: 1px 26px;
    text-decoration: none;
    font-size: 15px;



		.onr-footer__item--link {
			&:link {
				color: white;
				text-decoration: none;
			}

			&:visited {
				color: white;
				text-decoration: none;
			}

			&:hover {
				color: white;
				text-decoration: none;
			}

			&:active {
				color: white;
				text-decoration: none;
			}
		}
	}
}

</style>